import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ModeSwitcher from "./components/ModeSwitcher";
import LanguageSelector from "./components/LanguageSelector";
import HamburgeMenu from "./components/HamburgeMenu";

import { routes } from "../../configs/routes/routes";

import lightLogo from "../../../assets/image/light-logo.svg";
import darkLogo from "../../../assets/image/dark-logo.svg";

import "./Navbar.css";

const Navbar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const active = ({ isActive }) => ({
    color: "var(--txt-primary-color)",
    borderBottom: isActive ? "2px solid var(--txt-primary-color)" : "none",
    height: "20px",
  });

  const visibleRoutes = routes.filter((item) => item.isVisibleNavbar);

  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedMode = localStorage.getItem("mode");
    return savedMode === "dark";
  });

  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add("dark-mode");
    } else {
      document.body.classList.remove("dark-mode");
    }
  }, [isDarkMode]);

  const handleToggle = () => {
    setIsDarkMode((prev) => {
      const newMode = !prev ? "dark" : "light";
      localStorage.setItem("mode", newMode);
      return !prev;
    });
  };

  return (
    <div className="navbar_main">
      <div className="navbar_container">
        <HamburgeMenu />
        <img
          onClick={() => navigate("/")}
          className="navbar_logo"
          src={isDarkMode ? darkLogo : lightLogo}
          alt="logo"
        />
        <div className="navbar_items">
          {visibleRoutes.map((item) => (
            <NavLink key={item.path} style={active} to={item.path}>
              {t(item.title)}
            </NavLink>
          ))}
        </div>
        <div className="navbar_theme_mode_language">
          {window?.location.host.includes("localhost") && (
            <div className="navbar_theme_mode_language_mode">
              <ModeSwitcher
                isDarkMode={isDarkMode}
                handleToggle={handleToggle}
              />
            </div>
          )}

          <LanguageSelector />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
