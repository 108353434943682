import React, { useState, useEffect, useRef } from "react";

import i18n from "../../../configs/translation/i18nConfig";

import "./LanguageSelector.css";

const languages = [
  { code: "en", label: "En" },
  { code: "ru", label: "Ру" },
  { code: "uz", label: "O'z" },
];

const LanguageSelector = () => {
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("language") || "en"
  );
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => setIsOpen((prev) => !prev);

  const handleLanguageChange = (code) => {
    setSelectedLanguage(code);
    setIsOpen(false);
    i18n.changeLanguage(code);
    localStorage.setItem("language", code);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="language_dropdown" ref={dropdownRef}>
      <div className="language_selected" onClick={toggleDropdown}>
        <span className="language_label">
          {languages.find((lang) => lang.code === selectedLanguage)?.label}
        </span>
        <span className={`arrow ${isOpen ? "up" : "down"}`} />
      </div>
      {isOpen && (
        <ul className="language_menu">
          {languages.map(({ code, label }) => (
            <li
              key={code}
              className={`language_item ${
                code === selectedLanguage ? "selected" : ""
              }`}
              onClick={() => handleLanguageChange(code)}
            >
              {label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default LanguageSelector;
