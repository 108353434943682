import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";

import "./DiscussProjectSection.css";

gsap.registerPlugin(ScrollTrigger);

const DiscussProjectSection = () => {
  const sectionRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    const sectionElement = sectionRef.current;

    if (!sectionElement) return;

    const animationContext = gsap.context(() => {
      gsap.fromTo(
        ".project_idea_title",
        { opacity: 0, y: -30 },
        {
          opacity: 1,
          y: 0,
          duration: 1.5,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".project_idea_title",
            start: "top 90%",
            scrub: true,
          },
        }
      );

      gsap.fromTo(
        ".project_idea_description",
        { opacity: 0, x: -30 },
        {
          opacity: 1,
          x: 0,
          duration: 1.5,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".project_idea_description",
            start: "top 95%",
            scrub: true,
          },
        }
      );

      gsap.fromTo(
        ".project_idea_button",
        { opacity: 0, scale: 0.8 },
        {
          opacity: 1,
          scale: 1,
          duration: 1.5,
          ease: "elastic.out(1, 0.5)",
          scrollTrigger: {
            trigger: ".project_idea_button",
            start: "top 90%",
            scrub: true,
          },
        }
      );
    }, sectionElement);

    return () => {
      animationContext.revert();
    };
  }, []);

  return (
    <section className="project_idea_section" ref={sectionRef}>
      <div className="project_idea_container">
        <h2
          className="project_idea_title"
          dangerouslySetInnerHTML={{ __html: t("discuss_project_heading") }}
        ></h2>
        <p className="project_idea_description">
          {t("discuss_project_description")}
        </p>
        <div className="project_idea_button">
          {t("discuss_project_button")}
          <Icon className="deal" icon="game-icons:shaking-hands" width={30} />
        </div>
      </div>
    </section>
  );
};

export default DiscussProjectSection;
