import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { gsap } from "gsap";
import { Icon } from "@iconify/react";

import "./MainSection.css";

const MainSection = () => {
  const { t } = useTranslation();
  const textRef = useRef(null);
  const buttonsRef = useRef(null);

  useEffect(() => {
    const tl = gsap.timeline({
      defaults: { duration: 1.2, ease: "power4.out" },
    });

    tl.fromTo(
      textRef.current,
      { opacity: 0, y: 100, scale: 0.95, rotationX: -30 },
      { opacity: 1, y: 0, scale: 1, rotationX: 0 }
    ).fromTo(
      buttonsRef.current,
      { opacity: 0, y: 20 },
      { opacity: 1, y: 0, stagger: 0.2 },
      "-=0.6"
    );
  }, []);

  return (
    <section className="main_section">
      <div className="centered_content" ref={textRef}>
        <h1 className="centered_heading">{t("main_heading")}</h1>
        <p className="centered_paragraph">{t("main_paragraph")}</p>
        <div className="action_buttons" ref={buttonsRef}>
          <a
            href="#projects"
            className="button primary_button"
            aria-label={t("Explore My Work")}
          >
            {t("explore_my_work")}
          </a>
          <a
            href="#contact"
            className="button secondary_button"
            aria-label={t("Let’s Collaborate")}
          >
            {t("lets_collaborate")}
          </a>
        </div>
      </div>
      <div className="scroll_down">
        <span>{t("scroll_down")}</span>
        <Icon icon="mdi:arrow-down" className="scroll_icon" />
      </div>
    </section>
  );
};

export default MainSection;
