import React, { useState, useEffect } from "react";
import { Turn } from "hamburger-react";
import { NavLink } from "react-router-dom";

// import ModeSwitcher from "./ModeSwitcher";

import { routes } from "../../../configs/routes/routes";

import "./HamburgeMenu.css";

const HamburgeMenu = () => {
  const [isOpen, setOpen] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedMode = localStorage.getItem("mode");
    return savedMode === "dark";
  });

  const visibleRoutes = routes.filter((item) => item.isVisibleNavbar);

  if (isOpen) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "auto";
  }

  // useEffect(() => {
  //   if (isDarkMode) {
  //     document.body.classList.add("dark-mode");
  //   } else {
  //     document.body.classList.remove("dark-mode");
  //   }
  // }, [isDarkMode]);

  // const handleToggle = () => {
  //   setIsDarkMode((prev) => {
  //     const newMode = !prev ? "dark" : "light";
  //     localStorage.setItem("mode", newMode);
  //     return !prev;
  //   });
  // };

  const active = ({ isActive }) => ({
    color: "var(--txt-primary-color)",
    borderBottom: isActive ? "2px solid var(--txt-primary-color)" : "none",
  });

  return (
    <div className="hamburger_menu">
      <Turn
        color="var(--txt-color)"
        direction="right"
        toggled={isOpen}
        toggle={setOpen}
      />
      <div
        style={isOpen ? { transform: "translateX(0%)" } : null}
        className="hamburger_menu_items_wrapper"
      >
        <div className="hamburger_menu_items">
          {visibleRoutes.map((item) => (
            <NavLink
              style={active}
              onClick={() => setOpen(() => false)}
              to={item.path}
              key={item.id}
            >
              {item.title}
            </NavLink>
          ))}
          {/* <ModeSwitcher isDarkMode={isDarkMode} handleToggle={handleToggle} /> */}
        </div>
      </div>
    </div>
  );
};

export default HamburgeMenu;
