import React from "react";

import MainSection from "../../main/MainSection";
import AboutSection from "../../about/AboutSection";
import WorkProcessSection from "../../work-process/WorkProcessSection";
import PortfolioSection from "../../portfolio/PortfolioSection";
import DiscussProjectSection from "../../discuss-project/DiscussProjectSection";

const Home = () => {
  return (
    <>
      <MainSection />
      <AboutSection />
      <WorkProcessSection />
      <PortfolioSection />
      <DiscussProjectSection />
    </>
  );
};

export default Home;
