import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Lottie from "lottie-react";
import { useNavigate } from "react-router-dom";

import developerAnimation from "../assets/animation/developerAnimation.json";

import "./AboutSection.css";

gsap.registerPlugin(ScrollTrigger);

const AboutSection = () => {
  const { t } = useTranslation();
  const sectionRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const sectionElement = sectionRef.current;

    if (!sectionElement) return;

    const animationContext = gsap.context(() => {
      gsap.fromTo(
        ".about_section_heading",
        { opacity: 0, y: -20 },
        {
          opacity: 1,
          y: 0,
          duration: 1.2,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".about_section_heading",
            start: "top 90%",
          },
          scrub: true,
        }
      );

      gsap.fromTo(
        ".about_section_text",
        { opacity: 0, x: -20 },
        {
          opacity: 1,
          x: 0,
          duration: 1.2,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".about_section_text",
            start: "top 95%",
            scrub: true,
          },
        }
      );

      gsap.fromTo(
        ".about_section_features li",
        { opacity: 0, x: -20 },
        {
          opacity: 1,
          x: 0,
          stagger: 0.2,
          duration: 2,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".about_section_features",
            start: "top 95%",
            scrub: true,
          },
        }
      );

      gsap.fromTo(
        ".about_section_button",
        { opacity: 0, x: -30 },
        {
          opacity: 1,
          x: 0,
          stagger: 0.2,
          duration: 1,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".about_section_features",
            start: "top 90%",
            scrub: true,
          },
        }
      );
    }, sectionElement);

    return () => {
      animationContext.revert();
    };
  }, []);

  return (
    <section className="about_section" ref={sectionRef}>
      <div className="about_section_wrapper">
        <div className="about_section_content">
          <h2 className="about_section_heading">{t("about_heading")}</h2>
          <p className="about_section_text">{t("about_preview_text")}</p>
          <ul className="about_section_features">
            <li>{t("about_feature_1")}</li>
            <li>{t("about_feature_2")}</li>
            <li>{t("about_feature_3")}</li>
          </ul>
          <button
            className="about_section_button"
            onClick={() => navigate("/about")}
          >
            {t("learn_more_button")}
          </button>
        </div>

        <div className="about_section_visual">
          <Lottie animationData={developerAnimation} loop={true} />
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
