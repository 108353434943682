import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";

import "./PortfolioSection.css";

gsap.registerPlugin(ScrollTrigger);

const projects = [
  {
    id: 1,
    title: "Salez",
    type: "DASHBOARD",
    image:
      "https://i.pinimg.com/736x/43/44/b4/4344b4590a0b2c68761913cde908b197.jpg",
  },
  {
    id: 2,
    title: "Salez",
    type: "WEB APP",
    image:
      "https://i.pinimg.com/736x/43/44/b4/4344b4590a0b2c68761913cde908b197.jpg",
  },
  {
    id: 3,
    title: "Spectrum",
    type: "DASHBOARD",
    image:
      "https://i.pinimg.com/736x/43/44/b4/4344b4590a0b2c68761913cde908b197.jpg",
  },
  {
    id: 4,
    title: "Ecouz.uz",
    type: "WEBSITE",
    image:
      "https://i.pinimg.com/736x/43/44/b4/4344b4590a0b2c68761913cde908b197.jpg",
  },
  {
    id: 5,
    title: "Ecouz.uz",
    type: "DASHBOARD",
    image:
      "https://i.pinimg.com/736x/43/44/b4/4344b4590a0b2c68761913cde908b197.jpg",
  },
  {
    id: 6,
    title: "Olmazor-edu",
    type: "WEBSITE",
    image:
      "https://i.pinimg.com/736x/43/44/b4/4344b4590a0b2c68761913cde908b197.jpg",
  },
  {
    id: 7,
    title: "249-maktab",
    type: "WEBSITE",
    image:
      "https://i.pinimg.com/736x/43/44/b4/4344b4590a0b2c68761913cde908b197.jpg",
  },
  {
    id: 8,
    title: "Plant",
    type: "WEBSITE",
    image:
      "https://i.pinimg.com/736x/43/44/b4/4344b4590a0b2c68761913cde908b197.jpg",
  },
  {
    id: 9,
    title: "Greenshop",
    type: "WEBSITE",
    image:
      "https://i.pinimg.com/736x/43/44/b4/4344b4590a0b2c68761913cde908b197.jpg",
  },
  {
    id: 10,
    title: "Houzing",
    type: "WEBSITE",
    image:
      "https://i.pinimg.com/736x/43/44/b4/4344b4590a0b2c68761913cde908b197.jpg",
  },
  {
    id: 11,
    title: "Pizza",
    type: "WEBSITE",
    image:
      "https://i.pinimg.com/736x/43/44/b4/4344b4590a0b2c68761913cde908b197.jpg",
  },
  {
    id: 12,
    title: "Habib Ilm Markazi",
    type: "WEBSITE",
    image:
      "https://i.pinimg.com/736x/43/44/b4/4344b4590a0b2c68761913cde908b197.jpg",
  },
  {
    id: 13,
    title: "Weather App",
    type: "WEBSITE",
    image:
      "https://i.pinimg.com/736x/43/44/b4/4344b4590a0b2c68761913cde908b197.jpg",
  },
];

const PortfolioSection = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const portfolioRef = useRef(null);

  useEffect(() => {
    const elements = portfolioRef.current.querySelectorAll(".portfolio_card");

    gsap.fromTo(
      elements,
      { y: 100, opacity: 0 },
      {
        y: 0,
        opacity: 1,
        duration: 1.5,
        stagger: 0.2,
        ease: "power3.out",
        scrollTrigger: {
          trigger: portfolioRef.current,
          start: "top bottom",
          end: "top top",
          scrub: true,
        },
      }
    );
  }, []);

  return (
    <div className="portfolio">
      <div className="portfolio_container" ref={portfolioRef}>
        <div className="portfolio_header">
          <h2 className="portfolio_heading">{t("portfolio_heading")}</h2>
          <div className="portfolio_filters">
            <button className="portfolio_filter portfolio_filter_active">
              {t("portfolio_filter_all")}
            </button>
            <button className="portfolio_filter">
              {t("portfolio_filter_landing")}
            </button>
            <button className="portfolio_filter">
              {t("portfolio_filter_web_app")}
            </button>
            <button className="portfolio_filter">
              {t("portfolio_filter_dashboard")}
            </button>
          </div>
        </div>
        <div className="portfolio_grid">
          {projects.map((project) => (
            <div className="portfolio_card" key={project.id}>
              <img
                src={project.image}
                alt={project.title}
                className="portfolio_image"
              />
              <div className="portfolio_overlay">
                <h3 className="portfolio_title">{project.title}</h3>
                <p className="portfolio_type">{project.type}</p>
                <div className="portfolio_cta">
                  <span className="portfolio_view-more">
                    {t("portfolio_view_more")}
                  </span>
                  <Icon
                    icon="iconamoon:arrow-top-right-5-circle-fill"
                    className="portfolio_icon"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
        <button
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            marginTop: "20px",
            fontSize: "1rem",
          }}
          className="portfolio_filter portfolio_filter_active"
          onClick={() => navigate("/portfolio")}
        >
          {t("portfolio_all_projects")}
          <Icon
            icon="iconamoon:arrow-top-right-5-circle-fill"
            className="portfolio_icon"
            width={26}
          />
        </button>
      </div>
    </div>
  );
};

export default PortfolioSection;
