import React, { useEffect, useRef } from "react";
import "./WorkProcessSection.css";
import { Icon } from "@iconify/react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useTranslation } from "react-i18next";

gsap.registerPlugin(ScrollTrigger);

const WorkProcessSection = () => {
  const { t } = useTranslation();
  const sectionRef = useRef(null);
  const headingRef = useRef(null);
  const descriptionRef = useRef(null);
  const stepsRef = useRef([]);

  useEffect(() => {
    const ctx = gsap.context(() => {
      const timeline = gsap.timeline();

      timeline
        .from(headingRef.current, {
          opacity: 0,
          y: -50,
          duration: 1.2,
          ease: "power3.out",
          scrollTrigger: {
            trigger: ".work_process_heading",
            start: "top 80%",
            end: "bottom 60%",
            scrub: true,
          },
        })

        .from(
          descriptionRef.current,
          {
            opacity: 0,
            y: -40,
            duration: 1,
            ease: "power3.out",
            scrollTrigger: {
              trigger: ".work_process_description",
              start: "top 85%",
              end: "bottom 60%",
              scrub: true,
            },
          },
          "-=0.8"
        )

        .from(
          stepsRef.current,
          {
            opacity: 0,
            y: 50,
            scale: 0.9,
            duration: 0.9,
            stagger: 0.2,
            ease: "back.out(1.7)",
            scrollTrigger: {
              trigger: ".work_process_steps",
              start: "top 85%",
              end: "bottom 60%",
              scrub: true,
            },
          },
          "-=1.5"
        );
    }, sectionRef);

    return () => ctx.revert();
  }, []);

  const steps = [
    {
      icon: "fluent:globe-search-24-regular",
      wrapperColor: "#e8f4fc",
      iconColor: "#007bff",
      title: t("step_1_title"),
      description: t("step_1_description"),
    },
    {
      icon: "akar-icons:statistic-up",
      wrapperColor: "#ffe8f3",
      iconColor: "#e91e63",
      title: t("step_2_title"),
      description: t("step_2_description"),
    },
    {
      icon: "mdi:palette-outline",
      wrapperColor: "#fff9e5",
      iconColor: "#ffb300",
      title: t("step_3_title"),
      description: t("step_3_description"),
    },
    {
      icon: "mdi:rocket-launch-outline",
      wrapperColor: "#e5f7e9",
      iconColor: "#4caf50",
      title: t("step_4_title"),
      description: t("step_4_description"),
    },
  ];

  return (
    <section ref={sectionRef} className="work_process">
      <div className="work_process_container">
        <div className="work_process_header">
          <h2 ref={headingRef} className="work_process_heading">
            {t("work_heading")}
          </h2>
          <p ref={descriptionRef} className="work_process_description">
            {t("work_description")}
          </p>
        </div>
        <div className="work_process_steps">
          {steps.map((step, index) => (
            <div
              key={index}
              className="work_process_step"
              ref={(el) => (stepsRef.current[index] = el)}
            >
              <div
                className="work_process_icon_wrapper"
                style={{ backgroundColor: step.wrapperColor }}
              >
                <Icon
                  icon={step.icon}
                  className="work_process_icon"
                  style={{ color: step.iconColor }}
                />
              </div>
              <h3 className="work_process_step_title">{`${index + 1}. ${
                step.title
              }`}</h3>
              <p className="work_process_step_description">
                {step.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WorkProcessSection;
